





























































import {ComponentProxy} from '@labor-digital/helferlein/lib/Entities/ComponentProxy';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppEventKeys} from '../../../../../AppEventKeys';
import {JobDemand} from '../../../../../Interface/JobDemand';
import {JobService} from '../../../../../Service/JobService';
import Checkbox from '../../../../Form/Checkbox/Checkbox.vue';
import Chips from '../../../../Form/Chips/Chips.vue';
import InputField from '../../../../Form/InputField/InputField.vue';
import SelectBox from '../../../../Form/SelectBox/SelectBox.vue';
import JobContractTypeFilter from '../../../../Job/JobFilter/Type/JobContractTypeFilter/JobContractTypeFilter.vue';
import JobDateFilter from '../../../../Job/JobFilter/Type/JobDateFilter/JobDateFilter.vue';
import JobEmploymentTypeFilter from '../../../../Job/JobFilter/Type/JobEmploymentTypeFilter/JobEmploymentTypeFilter.vue';
import JobLocationFilter from '../../../../Job/JobFilter/Type/JobLocationFilter/JobLocationFilter.vue';
import JobOccupationFilter from '../../../../Job/JobFilter/Type/JobOccupationFilter/JobOccupationFilter.vue';
import JobQueryFilter from '../../../../Job/JobFilter/Type/JobQueryFilter/JobQueryFilter.vue';
import Btn from '../../../../Misc/Btn/Btn.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'FindJobModule',
    components: {
        JobContractTypeFilter,
        JobDateFilter,
        JobOccupationFilter,
        JobEmploymentTypeFilter,
        JobLocationFilter,
        JobQueryFilter,
        InputField, Checkbox, LinkWrap, SelectBox, Icon, Btn, Chips
    },
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            proxy: new ComponentProxy(this),
            unbinder: () => {},
            result: '...',
            page: 0,
            infiniteId: 1,
            isFilterShown: false
        };
    },
    computed: {
        jobCount(): string
        {
            if (this.result === '...') {
                return this.result;
            } else {
                return this.result.toLocaleString();
            }
        }
    },
    methods: {
        isFilterExpanded(): boolean
        {
            return this.isFilterShown;
        },
        toggleFilter()
        {
            this.isFilterShown = !this.isFilterExpanded();
        },
        updateJobCount()
        {
            this.result = '...';
            JobService.getDemandedCount().then(count => {
                this.result = count;
            });
        },
        onDemandFilter(demand: JobDemand)
        {
            // Set static values for this type of request
            if (demand.radius !== null) {
                demand.radius = null;
            }
        },
        onSearchClick()
        {
            this.proxy.emit(this.context.eventEmitter, AppEventKeys.CLOSE_MAIN_MENU_DROPDOWN);
        }
    },
    created()
    {

        // Update the job count if the demand was changed
        this.updateJobCount();
        this.unbinder = JobService.watchDemand(() => {
            this.updateJobCount();
        });
    },
    beforeDestroy()
    {
        this.unbinder();
    }
};
